import React from "react";
import { Container, Box, Typography, Button, Grid, Card, CardContent } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
    return (<>
        <Navbar />
        <Container maxWidth="lg" sx={{ py: 5 }}>
            {/* Hero Section */}
            <Box textAlign="center" mb={6}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Welcome to Jobvaults
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Your One-Stop Solution for Tracking and Analyzing Job Applications
                </Typography>
                <Button component={Link} to="/login" variant="contained" color="primary" sx={{ mt: 3 }}>
                    Get Started
                </Button>
            </Box>

            {/* Features Section */}
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={4}>
                    <Card elevation={3}>
                        <CardContent>
                            <WorkIcon color="primary" fontSize="large" />
                            <Typography variant="h6" component="h2" gutterBottom>
                                Email Integration
                            </Typography>
                            <Typography>
                                Seamlessly connect with your email to automatically gather job applications and relevant data.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card elevation={3}>
                        <CardContent>
                            <TimelineIcon color="primary" fontSize="large" />
                            <Typography variant="h6" component="h2" gutterBottom>
                                Real-Time Application Tracking
                            </Typography>
                            <Typography>
                                Stay updated on your application statuses, interviews, and offers with real-time tracking.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card elevation={3}>
                        <CardContent>
                            <AssessmentIcon color="primary" fontSize="large" />
                            <Typography variant="h6" component="h2" gutterBottom>
                                Detailed Insights & Analytics
                            </Typography>
                            <Typography>
                                Receive insights on your job application patterns and get tips to improve your success rate.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* How It Works Section */}
            <Box my={8} textAlign="center">
                <Typography variant="h4" gutterBottom>
                    How It Works
                </Typography>
                <Typography variant="h6" mb={4}>
                    A simple three-step process to manage and track your job applications effortlessly.
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" color="primary">
                            Step 1
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Connect Your Email
                        </Typography>
                        <Typography>
                            Securely connect your email to allow JobTrackPro to fetch and analyze job application emails.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" color="primary">
                            Step 2
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            View Application Dashboard
                        </Typography>
                        <Typography>
                            Access a personalized dashboard to see all your applications in one place, sorted by status.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" color="primary">
                            Step 3
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Get Insights and Notifications
                        </Typography>
                        <Typography>
                            Receive regular insights on your application progress and alerts for interview updates.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* Call to Action Section */}
            <Box textAlign="center" mt={8} mb={4}>
                <Typography variant="h4" gutterBottom>
                    Ready to Optimize Your Job Hunt?
                </Typography>
                <Typography variant="h6" mb={4}>
                    Take control of your applications and stay on top of your career journey with JobTrackPro.
                </Typography>
                <Button component={Link} to="/register" variant="contained" color="primary" size="large">
                    Sign Up Today
                </Button>
            </Box>
        </Container>
    </>
    );
};

export default HomePage;