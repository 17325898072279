import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";
import HomePage from "pages/HomePage";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfServices from "pages/TermsOfServices";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// user management
const EmailList = Loadable(
  lazy(() => import("./pages/userManagement/EmailList"))
);

const ResetPassword = Loadable(
  lazy(() => import("./pages/authentication/ResetPassword"))
);

const JobList = Loadable(
  lazy(() => import("./pages/userManagement/JobList"))
);

const ReviewList = Loadable(
  lazy(() => import("./pages/userManagement/ReviewList"))
);

const TermsAndConditions = Loadable(
  lazy(() => import("./pages/Terms&Conditions"))
)

const Accounts = Loadable(
  lazy(() => import("./pages/userManagement/Accounts"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="home" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfServices />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "home",
    element: <HomePage />,
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },

      {
        path: "emails",
        element: <EmailList />,
      },
      {
        path: "jobs",
        element: <JobList />,
      },
      {
        path: "accounts",
        element: <Accounts />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
      {
        path: "review",
        element: <ReviewList />,
      },

    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
