import React from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const Navbar: React.FC = () => {
  return (
    <AppBar position="static" style={{backgroundColor: '#59BCF5'}}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="home" component={Link} to="/">
          <img style={{height: 30, width: 30}} src="/assets/images/logo.png" />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Jobvaults
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* <Button color="inherit" component={Link} to="/features">
            Features
          </Button>
          <Button color="inherit" component={Link} to="/how-it-works">
            How It Works
          </Button>
          <Button color="inherit" component={Link} to="/about">
            About Us
          </Button>
          <Button color="inherit" component={Link} to="/contact">
            Contact
          </Button> */}
        </Box>
        {/* <Button color="inherit" component={Link} to="/login">
          Login
        </Button> */}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;