import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Your privacy is important to us. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to protect it.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal Information:</strong> We may collect information that identifies you personally, such as your name and email address, when you register for an account.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email Data:</strong> With your permission, we access your email data to help identify job-related emails. This data is processed within the application to provide you with insights into your job search process.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information collected to:
          <ul>
            <li>Provide and improve our services.</li>
            <li>Identify job-related emails and offer job application tracking features.</li>
            <li>Communicate with you about updates, features, and other relevant information.</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Data Sharing and Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share information with trusted third parties who assist us in operating our app, provided that these parties agree to keep this information confidential.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement industry-standard security measures to protect your data. However, please note that no electronic storage method is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We retain your information for as long as necessary to provide our services and comply with legal obligations. Upon your request, we will delete or anonymize your data, provided there are no legal requirements for its retention.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, correct, or delete your personal information. You may also withdraw your consent for data processing at any time. Contact us at <strong>support@yourapp.com</strong> for any requests or questions regarding your rights.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy periodically. If there are significant changes, we will notify you via email or through our application. Continued use of the application after changes indicates your acceptance of the updated policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have questions or concerns about this Privacy Policy, please contact us at <strong>support@yourapp.com</strong>.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;