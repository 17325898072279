import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const TermsOfService: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using our application, you agree to comply with these Terms of Service. If you do not agree with these terms, please do not use our application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Description of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Our application provides a platform to retrieve and analyze email data to help users track job application statuses, identify job-related emails, and view statistics on their job search process.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Data and Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email Data Collection:</strong> Our application accesses users' email data with their consent to identify job-related emails. The collected data will be processed solely to provide job-tracking insights.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Data Security:</strong> We prioritize data security and utilize industry-standard measures to protect user data. However, users acknowledge that no method of electronic storage is completely secure, and we cannot guarantee absolute security.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Data Usage:</strong> Email data and related statistics are only used within the application for job-tracking purposes and will not be shared with third parties unless explicitly authorized by the user.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          Users agree to provide accurate and current information for account registration, not use the application for any unlawful purposes, and respect the rights of other users by not engaging in any disruptive behavior or misuse of the application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content, trademarks, and other intellectual property rights related to the application are owned by or licensed to us. Users are granted limited rights to use the application but may not modify, distribute, or reproduce any part of the application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          The application is provided "as is," and we make no warranties regarding its accuracy, reliability, or completeness. We shall not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use the application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Modifications to Service and Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify or discontinue the application or these Terms of Service at any time. Users will be notified of any significant changes, and continued use of the application constitutes acceptance of the updated terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to suspend or terminate a user's access to the application at our discretion if these Terms of Service are violated.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          For questions or concerns about these Terms of Service, please contact us at <strong>contact.jobvaults@gmail.com</strong>.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;